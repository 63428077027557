import React, { useState, useEffect } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import useScript from "../service/useScript"
import ContactHelpLine from "../components/pageSections/helpline"
import { graphql } from "gatsby"

const ContactHelpline = (props) => {
    const isSSR = typeof window === "undefined"
    const { data } = props

    let pageInfo = {}
    let metaTags = []
    let schema = []
    let canonicalUrl = {}
    let helpLine = []
    if (data && data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
        pageInfo = data.allNodeTemplatePages.edges[0]
        metaTags = pageInfo?.node?.metatags ? pageInfo.node.metatags : []
        schema = pageInfo?.node?.relationships?.field_seo_schema ? pageInfo?.node?.relationships?.field_seo_schema : []
        canonicalUrl = pageInfo.node.metatags_on_page ||  {}
    }
    let [components, setComponents] = useState([])
    useEffect(async () => {
        const components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
        setComponents(components)
    }, [])
    if(components && components[1] && components[1]?.relationships?.field_components)
    {
        helpLine = components[1]?.relationships?.field_components
    }
    useScript("/assets/js/custom_select.js");

    const StandaloneMap = React.lazy(() =>
        import("../components/addon/maps/standaloneMap")
    )

return (
<>
<Layout>
    <Meta
    files={
    {
    js: [],
    css: ["/assets/css/services.css","/assets/css/helpline.css"]
    }
    }
    tags={{metaTags,canonicalUrl}}
    />
    <Schema schema={schema} />
    <main className="innerpage">
        <section class="section_bg section_healthcheckup pt-first-section">
            <div class="container">
                <div class="row mx-0 mb-5 helplineWrap">
                    <div class="col-lg-6 col-md-12 px-0 map-left-section">
                        <div class="d-flex w-100 h-100 align-items-center">
                        <div class="map-inner-text" dangerouslySetInnerHTML = {{ __html: components[0]?.htmlText?.processed }}/>   
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 px-0 map-section">
                        {!isSSR &&
                            <React.Suspense fallback={<div />}>
                                <StandaloneMap />
                            </React.Suspense>
                        }
                    </div>
                </div>
                <ContactHelpLine 
                    content = {helpLine} 
                    />
            </div>
        </section>
    </main>
</Layout>
</>
)
}
export default ContactHelpline
export const query = graphql`
query contactUsHelpline {
allNodeTemplatePages(filter: {path: {alias: {regex: "/contact-us-helpline$/"}}}) {
edges {
node {
id
title
path {
alias
}
relationships{
    field_seo_schema{
        field_seo_title
        field_seo_schema_text_area
    }
}
metatags: metatag_normalized {
attributes {
content
name
property
}
}
metatags_on_page: field_meta_tags{
    canonical_url
  }
relationships {
components: field_component_type {
__typename
...ParagraphHTMLText
... on paragraph__title_and_description_components {
id
relationships {
field_components {
... on paragraph__title_and_description {
id
field_title
field_description {
processed
}
}
}
}
}
}
}
}
}
}
}
`