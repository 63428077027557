import * as React from "react"
import { Link } from "gatsby"
 
const ContactHelpLine = (props) => {

    const { content,
          sectionClasses
     } = props

    return (
        <div class="row no-gutters">
        <div class="col-lg-12 col-md-12">
             
              <div class="checkup_slider no-slider">
                  <div class="row">

                {
                   content.map((data, key) => {
                    return( 
                    <div class="col-md-4 slide-col mb-4" key={key}>
                        <div class="slide">
                            <div class="checkup_card no-btns">
                                <h3 class="name">{ data.field_title }</h3>
                                <div dangerouslySetInnerHTML={{ __html: data?.field_description?.processed ? data.field_description.processed : '' }}></div>
                            </div>
                        </div>
                    </div>
                   )
                  })
                }

                </div>      
            </div>
        </div>
     </div>

    )
}
export default ContactHelpLine
